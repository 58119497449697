import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Link from "../components/Link";
import { useTranslation } from "gatsby-plugin-react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    color: "#fff",
    borderRadius: "5px",
    backgroundImage: ({ image }) => `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  btnBox: {
    justifyContent: "center",
    align: "center",
    textAlign: "center",
  },
  typ: {
    fontWeight: "bold",
    whiteSpace: "pre-wrap",
  },
  btn: {
    backgroundColor: "#fff",
    color: "#09333F",
    fontWeight: "bold",
    borderRadius: "15px",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  title: {
    fontWeight: "bold",
    fontSize: "3em",
    [theme.breakpoints.down("md")]: {
      fontSize: "3em",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2em",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
  },
  paddingTop: {
    paddingTop: "1rem",
  },
}));

export default function ImgMediaCard({ title, text, button, image, slug }) {
  const { t } = useTranslation();
  const classes = useStyles({ image: image?.images?.fallback?.src });

  return (
    <Paper className={classes.root} elevation={7}>
      <Container>
        <Grid container>
          <Grid item xs={12} className={classes.paddingTop}></Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.title}>
              {t(title)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.typ}>
              {t(text)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.btnBox}>
          <Button
            variant="contained"
            className={classes.btn}
            component={Link}
            href={`/${slug}`}
          >
            {t(button)}
          </Button>
        </Grid>
      </Container>
    </Paper>
  );
}
