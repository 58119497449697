import React, { useEffect, useRef } from "react";

const Video = ({ className, src, setVideoEnded }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef?.current?.firstChild) {
      divRef.current.firstChild.play();
      divRef.current.firstChild.addEventListener("ended", () => {
        setVideoEnded(true);
      });
    }
  }, []);

  return (
    <div
      ref={divRef}
      className={className}
      dangerouslySetInnerHTML={{
        __html: `<video style="width: 100%;" muted autoPlay loop>
            <source src="${src}" type="video/mp4" />
          </video>`,
      }}
    />
  );
};

export default Video;
