import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "./Card";
import { Container } from "@material-ui/core";
import SlideAnimation from "./SlideAnimation";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    color: "#fff",
    marginTop: "1rem",
  },
  conteinerPadding: {
    paddingBottom: "2rem",
  },
  typography: {
    color: "#09333F",
  },
  line: {
    paddingTop: "1rem",
    color: "#fff",
  },
  marginBottom: {
    marginBottom: "1rem",
  },
  gridStrategy: {
    padding: "1rem",
  },
  ueberschrift: {
    textAlign: "center",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    color: "#fff",
    fontWeight: "300",
  },
  ueberschrift2: {
    marginTop: "1rem",
    color: "#fff",
  },
  cards: {},
}));

const getServices = (services) => services?.allServicesJson?.edges || [];
const getService = (service) => ({
  ...service?.node,
  image: getImage(service?.node?.image),
});

export default function Types() {
  const classes = useStyles();
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            width
            direction
            title
            text
            button
            slug
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const services = getServices(data)
    .map((service) => getService(service))
    .map(({ width, direction, title, text, button, image, slug }, idx) => (
      <Grid key={idx} item xs={12} md={width} className={classes.gridStrategy}>
        <SlideAnimation direction={direction}>
          <Card
            title={title}
            text={text}
            button={button}
            image={image}
            slug={slug}
          />
        </SlideAnimation>
      </Grid>
    ));

  return (
    <div id="services" className={classes.root}>
      <Container className={classes.typography} maxWidth="lg">
        <Typography
          component="h1"
          variant="h3"
          gutterBottom
          className={classes.line}
          align="center"
        >
          {t("index.services.underTitleServices")}
        </Typography>
      </Container>
      <Container maxWidth="lg">
        <Grid item xs={12}>
          <div id="text">
            <Typography
              component="p"
              variant="h6"
              className={classes.marginBottom}
            >
              {t("index.services.textServices")}
            </Typography>
          </div>
        </Grid>
      </Container>

      <Container maxWidth="lg" className={classes.conteinerPadding}>
        <div>
          <Grid container className={classes.cards}>
            {services}
          </Grid>
        </div>
      </Container>
    </div>
  );
}
