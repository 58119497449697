import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Link from "./Link";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: "pre-wrap",
  },

  root: {
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "60vh",
  },

  card: {
    position: "relative",
    width: "auto",
    height: "460px",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    padding: "1rem",
    margin: "2rem",
    [theme.breakpoints.down("md")]: {
      width: "400px",
      height: "400px",
    },
    "&:hover": {
      "& $circle": {
        "& ::before": {
          background: "rgba(255, 255, 255, 0.8)",
          clipPath: "circle(150px at center)",
        },
      },
      "& $img": {
        display: "none",
      },
      "& $content": {
        left: 0,
        top: 0,
        opacity: 1,
        visibility: "visible",
        // transform: "translate(0, -10%)",
        "& $h3": {
          color: "#fff",
          textTransform: "uppercase",
          fontSize: "2em",
          lineHeight: "1em",
          marginBottom: "5px",
        },
      },
    },
  },
  content: {
    position: "relative",
    width: "100%",
    padding: "1rem",
    opacity: 0,
    visibility: "hidden",
    pointerEvents: "none",
    [theme.breakpoints.down("lg")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
    },
    "& $h3": {
      color: "#fff",
      textTransform: "uppercase",
      fontSize: "2em",
      lineHeight: "1em",
      marginBottom: "5px",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.3em",
      },
    },
    "& $p": {
      color: "#fff",
      whiteSpace: "pre-wrap",
      [theme.breakpoints.down("md")]: {
        fontSize: "1em",
      },
    },
  },

  circle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    overflow: "hidden",
    transition: "0.5s",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(255, 255, 255, 0.2)",
      clipPath: "circle(160px at center)",
      transition: "0.5s",
      [theme.breakpoints.down("md")]: {
        clipPath: "circle(140px at center)",
      },
    },
    "&:hover": {
      "&::before": {
        clipPath: "circle(460px at center)",
      },
    },
  },
  img: {
    position: "absolute !important",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    pointerEvents: "none",
    transition: "0.5s",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
  },
  contentGrid: {
    textAlign: "center",
  },
  btn: {
    backgroundColor: "#fff",
    color: "#09333F",
    fontWeight: "bold",
    borderRadius: "15px",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  icon: {
    textAlign: "center",
    fontSize: "200px",
  },
}));

const getWorkCards = (data) => data?.allWorkCardJson?.edges || [];

const getWorkCard = (workCard) => ({
  ...workCard?.node,
  icon: getImage(workCard?.node?.icon),
});

const WorkCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      allWorkCardJson {
        edges {
          node {
            title
            description
            href
            icon {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const workCards = getWorkCards(data)
    .map((workCard) => getWorkCard(workCard))
    .map(({ title, description, icon, href }) => (
      <>
        <Grid
          key={t(title)}
          item
          xs={12}
          md={6}
          lg={4}
          className={classes.root}
        >
          <Link href={href} className={classes.link}>
            <div className={classes.card}>
              <div className={classes.circle}></div>
              <div className={classes.content}>
                <h3 className={classes.h3}>{t(title)}</h3>
                <p
                  className={classes.p}
                  dangerouslySetInnerHTML={{ __html: t(description) }}
                />
              </div>
              <GatsbyImage
                placeholder="none"
                image={icon}
                alt={t(title)}
                className={classes.img}
              />
            </div>
          </Link>
        </Grid>
      </>
    ));

  return (
    <>
      <Grid container style={{ flex: "0 0 100%" }}>
        {workCards}
        <Grid item xs={12} md={6} lg={4} className={classes.root}>
          <a
            href="https://m3e.group/contact"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <div className={classes.card}>
              <div className={classes.circle}></div>
              <div className={classes.content}>
                <h3 className={classes.h3} alignItems="center">
                  {t("index.work.contactCard.title")}
                </h3>
                <Grid className={classes.contentGrid}>
                  <p className={classes.p}>
                    {t("index.work.contactCard.body")}
                  </p>
                  <Button className={classes.btn}>
                    {t("index.work.contactCard.button")}
                  </Button>
                </Grid>
              </div>

              <StaticImage
                src="../media/nav/du.png"
                placeholder="none"
                alt="M3E Communications"
                className={classes.img}
              />
            </div>
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkCard;
