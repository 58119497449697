import React from "react";
import Navbar from "../components/Navbar";
import Intro from "../components/Intro.js";
import Services from "../components/Services.js";
import Work from "../components/Work.js";
import We from "../components/We.js";
import Footer from "../components/Footer";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Seo from "../components/SEO/Seo";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#09333F",
    backgroundSize: "100% 100%",
  },
}));

function Index({ data }) {
  const classes = useStyles();
  const { site } = data;
  const { t } = useTranslation();

  return (
    <div id="index" className={classes.root}>
      <Seo
        site={site}
        title={t("seo.index.siteTitle")}
        description={t("seo.index.metaDescription")}
        //image={getSeoImage(image)}
      />
      <Navbar />
      <Intro />
      <Services />
      <We />
      <Work />
      <Footer />
    </div>
  );
}

export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        organization {
          name
          url
          logo
        }
      }
    }
  }
`;
