import { Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Link from "../components/Link";
import { Container } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";

const useStyles = makeStyles({
  root: {},
  box: {
    padding: "2rem 0rem",
    color: "white",
  },
  title: {
    textAlign: "center",
    paddingBottom: "2rem",
  },
  btn: {
    backgroundColor: "#fff",
    color: "#09333F",
    fontWeight: "bold",
    borderRadius: "15px",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  btnBox: {
    textAlign: "center",
  },
});

export default function We() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Container className={classes.root} id="we" maxWidth="lg">
      <Grid container>
        <Grid item className={classes.box}>
          <Typography variant="h3" className={classes.title}>
            {t("we.titleWe")}
          </Typography>
          <Typography component="div" variant="h6">
            <div dangerouslySetInnerHTML={{ __html: t("we.textWe") }} />
          </Typography>
          <Grid item xs={12} className={classes.btnBox}>
            <Button
              variant="contained"
              className={classes.btn}
              component={Link}
              href="/we"
            >
              {t("we.buttonWe")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
