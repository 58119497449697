import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VideoSrc from "../media/intro/VideoCommunications.mp4";
import { StaticImage } from "gatsby-plugin-image";
import Video from "./Video";
// import Typewriter from "../components/Typewriter";
import useWindowDimensions from "./hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: ({ ratio }) => ratio,
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  typewriter: {
    transform: "translateY(-500%)",
    [theme.breakpoints.down("sm")]: {
      transform: "translateY(-400%)",
    },
    [theme.breakpoints.down("xs")]: {
      transform: "translateY(-400%)",
    },
    [theme.breakpoints.down("md")]: {
      transform: "translateY(-500%)",
    },
  },
}));

export default function ImgMediaCard() {
  const [videoEnded, setVideoEnded] = useState(false);
  const { width } = useWindowDimensions();
  const ratio = Math.round((9 / 16) * width);
  const classes = useStyles({ ratio });

  return (
    <div className={classes.root}>
      <StaticImage
        src="../media/intro/VideoCommunications.png"
        alt="M3E"
        placeholder="none"
        formats={["auto", "webp", "avif"]}
        className={classes.image}
      />
      <Video
        className={classes.video}
        src={VideoSrc}
        setVideoEnded={setVideoEnded}
      />
      {/* {videoEnded && (
        <div className={classes.typewriter}>
          <Typewriter text="Communicating innovations and the future of mobility" />
        </div>
      )} */}
    </div>
  );
}
