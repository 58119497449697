import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import WorkCard from "./WorkCard";
import { Grid } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";

const useStyles = makeStyles((theme) => ({
  body: {
    margin: "0",
    padding: "0",
    boxSizing: "border-box",
  },
  root: {
    background: "transparent",
  },
  typography: {
    color: "#fff",
    textAlign: "center",
    paddingTop: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2em",
    },
  },
  cards: {
    marginBottom: "2rem",
  },
}));

export default function Types() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div id="work" className={classes.root}>
      <div>
        <Typography variant="h3" gutterBottom className={classes.typography}>
          {t("index.work.underTitleWork")}
        </Typography>
        <Typography variant="h4" gutterBottom className={classes.typography}>
          {t("index.work.titelWork")}
        </Typography>
      </div>
      <div>
        <Grid container item xs={12} className={classes.cards}>
          <WorkCard />
        </Grid>
      </div>
    </div>
  );
}
